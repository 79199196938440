@tailwind base;
@tailwind components;
@tailwind utilities;
/* In your main CSS file or a separate print CSS file */
@media print {
    /* Make sure background colors are printed */
    * {
      -webkit-print-color-adjust: exact; /* For Chrome/Safari */
      print-color-adjust: exact; /* For other browsers */
    }
  
    /* Optionally, hide certain elements */
    .no-print {
      display: none;
    }
  
    /* Ensure the header is styled correctly */
    header {
      display: block;
      /* Add any other styles needed for printing */
    }
  }
  